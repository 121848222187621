@import '../themes/theme';
@import 'base/typography';

@mixin icon-size($size) {
  font-size: $size;
  line-height: $size !important;
  width: $size !important;
  height: $size !important;
}

@mixin background-color-chip {
  background-color: $primary-color-400;
}
@mixin button-size($size) {
  line-height: $size;
  width: $size;
  height: $size;
}

@mixin aid-common {
  .white {
    color: $light-primary-text;
  }

  .brand-color {
    color: $primary-color !important;
  }

  .accent-color {
    color: $accent-color;
  }

  .gray-color {
    color: $black-shadow-8;
  }

  .light-gray-color {
    color: $black-shadow-4;
  }

  .accent-color {
    color: $accent-color;
  }

  .accent-color {
    color: $accent-color;
  }

  .warn-color {
    color: $warn-color;
  }

  .basic-light-color-600 {
    color: $basic-color-600;
  }

  .basic-light-color-300 {
    color: $basic-light-color-300;
  }

  .basic-color-900 {
    color: $basic-color-900;
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $primary-color !important;
  }

  .error-info {
    color: $warn-color;
    font-style: italic !important;
  }

  .relative {
    position: relative;
  }
  .absolute {
    position: absolute;
    top: 69px;
  }
  .absolute1 {
    position: absolute;
    top: 150px;
  }

  // Text styles
  .text-center {
    text-align: center;
  }

  .icon-size-16 {
    @include icon-size(16px);
  }

  .icon-size-24 {
    @include icon-size(24px);
  }

  .icon-size-32 {
    @include icon-size(32px);
  }

  .bold {
    font-weight: 600 !important;
  }

  .avatar {
    border-radius: 50%;
    font-size: 32px;
    width: 32px;
    height: 32px;
  }

  .loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $black-shadow-08;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  .empty-list {
    text-align: center;
    color: $secondary-text;
  }

  .pointer {
    cursor: pointer;
  }

  .display-none {
    display: none !important;
  }

  .label {
    color: #acb7cd;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    font-family: $font-family-source;
  }

  .width-50 {
    width: 50%;
  }

  .width-33 {
    width: 33%;
  }

  .width-40 {
    width: 40%;
  }

  .damage-border {
    padding-left: 16px;
    margin-left: 8px;
    border-left: 1px solid $accent-color;
  }

  .no-boarder {
    border: none !important;
  }

  .info-icon {
    color: $basic-color-600;
    @include icon-size(16px);
  }

  .add-button {
    min-width: 41px !important;
    padding: 0 !important;
    height: 41px;
    border-radius: 8px !important;
    margin-left: 16px !important;
  }
}

$x_small: 4px;
$small: 8px;
$medium: 16px;
$large: 32px;
