@use '@angular/material' as mat;

@import 'base/font';
@import 'base/typography';

@import 'layout/form';
@import 'layout/layout-utils';
@import 'layout/sidenav';
@import 'layout/table';
@import 'layout/mat-chip';
@import 'layout/mat-select';
@import 'layout/mat-card';
@import 'layout/tab';
@import 'layout/divider';
@import 'layout/button';
@import 'layout/mat-expansion-panel';
@import 'layout/mat-dialog';

@import 'themes/theme';

@import 'utilities/common';
@import 'utilities/media_queries';

@include aid-layout-utilities();
@include aid-common();
@include aid-form-styles();
@include aid-table-styles();
@include aid-mat-chips-styles();
@include aid-mat-select-styles();
@include aid-sidenav-styles();
@include aid-typography();
@include aid-mat-card-styles();
@include aid-mat-tab-styles();
@include aid-mat-divider-styles();
@include aid-button-styles();
@include aid-mat-expansion-panel-styles();
@include aid-mat-dialog-container();

$aidorando-theme: mat.define-light-theme($primary, $accent, $warn);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($aid-typography);`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies($aid-typography);
@include mat.all-component-typographies($aid-typography);
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();
/* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
@include mat.all-legacy-component-themes($aidorando-theme);
@include mat.all-component-themes($aidorando-theme);

$font-family: $font-family;

html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
}

/**
 overrides the snackbar properties
 */
.snackbar {
  width: 400px !important;
  //height: 78px !important;
  position: relative;
  display: flex !important;
  justify-content: space-around;
  padding: 16px !important;
  border: solid 1px $basic-light-color-300;
  box-shadow: 0 0 1px rgba(9, 30, 66, 0.31),
    0 20px 32px -8px rgba(9, 30, 66, 0.25);
  box-sizing: border-box !important;
  color: rgb(66, 82, 110);
  z-index: 600;
  border-radius: 8px !important;
  background-color: white;
  max-width: unset !important;
  min-width: unset !important;
}
