@import '../themes/theme';

@mixin padding($size) {
  padding: $size;
}

@mixin padding-bottom($size) {
  padding-bottom: $size;
}

@mixin padding-top($size) {
  padding-top: $size;
}

@mixin padding-right($size) {
  padding-right: $size;
}

@mixin padding-left($size) {
  padding-left: $size;
}

@mixin padding-horizontal($size) {
  padding-left: $size;
  padding-right: $size;
}

@mixin padding-vertical($size) {
  padding-top: $size;
  padding-bottom: $size;
}

@mixin margin($size) {
  margin: $size;
}

@mixin margin-bottom($size) {
  margin-bottom: $size;
}

@mixin margin-top($size) {
  margin-top: $size;
}

@mixin margin-right($size) {
  margin-right: $size;
}

@mixin margin-left($size) {
  margin-left: $size;
}

@mixin margin-horizontal($size) {
  margin-left: $size;
  margin-right: $size;
}

@mixin margin-vertical($size) {
  margin-top: $size;
  margin-bottom: $size;
}

@mixin aid-layout-utilities() {
  .flex {
    display: flex;
  }

  .flex-1 {
    flex: 1;
  }

  .full-width {
    width: 100%;
  }
  .width-40 {
    width: 40%;
  }
  .width-70 {
    width: 70%;
  }

  .full-height {
    height: 100%;
  }

  .height-50 {
    height: 50%;
  }

  .fill {
    flex: 1 1 auto;
  }

  .padding-4 {
    @include padding(4px);
  }

  .padding-8 {
    @include padding(8px);
  }

  .padding-16 {
    @include padding(16px);
  }

  .padding-32 {
    @include padding(32px);
  }

  .padding-64 {
    @include padding(64px);
  }

  .padding-top-16 {
    @include padding-top(16px);
  }

  .padding-top-8 {
    @include padding-top(8px);
  }

  .padding-top-32 {
    @include padding-top(32px);
  }

  .padding-top-64 {
    @include padding-top(64px);
  }

  .padding-bottom-4 {
    @include padding-bottom(4px);
  }

  .padding-bottom-8 {
    @include padding-bottom(8px);
  }

  .padding-bottom-16 {
    @include padding-bottom(16px);
  }

  .padding-bottom-24 {
    @include padding-bottom(24px);
  }

  .padding-bottom-32 {
    @include padding-bottom(32px);
  }

  .padding-bottom-40 {
    @include padding-bottom(40px);
  }

  .padding-vertical-16 {
    @include padding-vertical(16px);
  }

  .padding-vertical-8 {
    @include padding-vertical(8px);
  }

  .padding-vertical-24 {
    @include padding-vertical(24px);
  }

  .padding-vertical-32 {
    @include padding-vertical(32px);
  }

  .padding-vertical-64 {
    @include padding-vertical(64px);
  }

  .padding-horizontal-8 {
    @include padding-horizontal(8px);
  }

  .padding-horizontal-16 {
    @include padding-horizontal(16px);
  }

  .padding-horizontal-24 {
    @include padding-horizontal(24px);
  }

  .padding-horizontal-32 {
    @include padding-horizontal(32px);
  }

  .padding-horizontal-64 {
    @include padding-horizontal(64px);
  }

  .padding-horizontal-top-64 {
    padding: 64px 64px 0 64px;
  }

  .padding-horizontal-bottom-64-top-32 {
    padding: 32px 64px 64px 64px;
  }
  .padding-horizontal-bottom-64 {
    padding: 0 64px 64px 64px;
  }

  .padding-horizontal-bottom-32 {
    padding: 0 32px 32px 32px;
  }

  .padding-vertical-right-8-left-32 {
    padding: 8px 8px 8px 32px;
  }

  .padding-right-4 {
    @include padding-right(4px);
  }
  .padding-right-8 {
    @include padding-right(8px);
  }
  .padding-right-16 {
    @include padding-right(16px);
  }

  .padding-right-32 {
    @include padding-right(32px);
  }

  .padding-left-4 {
    @include padding-left(4px);
  }

  .padding-left-8 {
    @include padding-left(8px);
  }

  .padding-left-16 {
    @include padding-left(16px);
  }

  .padding-left-24 {
    @include padding-left(24px);
  }

  .padding-left-32 {
    @include padding-left(32px);
  }

  .padding-left-40 {
    @include padding-left(40px);
  }

  .padding-left-48 {
    @include padding-left(48px);
  }

  .padding-left-64 {
    @include padding-left(64px);
  }

  .padding-left-128 {
    @include padding-left(128px);
  }

  .no-margin {
    @include margin(0);
  }

  .margin-8 {
    @include margin(8px);
  }

  .margin-16 {
    @include margin(16px);
  }

  .margin-32 {
    @include margin(32px);
  }

  .margin-64 {
    @include margin(64px);
  }

  .margin-top-8 {
    @include margin-top(8px);
  }

  .margin-top-16 {
    @include margin-top(16px);
  }

  .margin-top-32 {
    @include margin-top(32px);
  }

  .margin-bottom-16 {
    @include margin-bottom(16px);
  }

  .margin-bottom-8 {
    @include margin-bottom(8px);
  }

  .margin-bottom-64 {
    @include margin-bottom(64px);
  }

  .margin-right-8 {
    @include margin-right(8px);
  }

  .margin-right-32 {
    @include margin-right(32px);
  }

  .margin-left-16 {
    @include margin-left(16px);
  }

  .margin-left-32 {
    @include margin-left(32px);
  }

  .margin-horizontal-32 {
    @include margin-horizontal(32px);
  }

  .margin-horizontal-8 {
    @include margin-horizontal(8px);
  }

  .margin-vertical-32 {
    @include margin-vertical(32px);
  }

  .margin-bottom-32 {
    @include margin-bottom(32px);
  }

  .submit-button {
    height: 48px;
    width: 84px;
  }

  .border {
    border: 1px solid $basic-color-500 !important;
  }

  .border-dashed {
    border: 1px dashed $basic-color-500 !important;
  }

  .border-radius-8 {
    border-radius: 8px;
  }

  .overflow {
    overflow: auto;
  }

  .no-padding {
    padding: 0 !important;
  }

  .max-height {
    max-height: 100%;
  }

  .container-page {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .break-word {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }
}
