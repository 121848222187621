@use '@angular/material' as mat;
@import '../../../node_modules/@angular/material/theming';
@import 'themes/theme';

/**

 Typography from Material Specs https://material.io/design/typography/the-type-system.html#applying-the-type-scale

  H1 - display-4
  H2 - display-3
  H3 - display-2
  H4 - display 1
  H5 - headline
  H6 - title
  subtitle 1 - subheading-1
  subtitle 2 - subheading-2

 **/

$font-family: 'Open Sans', sans-serif;
$font-family-source: 'Source Sans Pro', sans-serif;

$aid-typography: mat.define-typography-config(
  $font-family: $font-family,
  $headline-1:
    mat.define-typography-level(96px, 96px, 300, $font-family, -1.5px),
  $headline-2:
    mat.define-typography-level(60px, 60px, 300, $font-family, -0.5px),
  $headline-3: mat.define-typography-level(48px, 48px, 400, $font-family),
  $headline-4:
    mat.define-typography-level(32px, 32px, 600, $font-family, 0.25px),
  $headline-5: mat.define-typography-level(24px, 24px, 400, $font-family, 0px),
  $headline-6:
    mat.define-typography-level(20px, 27px, 500, $font-family, 0.15px),
  $body-1: mat.define-typography-level(16px, 24px, 400, $font-family, 0.15px),
  $subtitle-1: mat.define-typography-level(14px, 16px, 600, $font-family, 0.1px),
  $body-2: mat.define-typography-level(16px, 20px, 600, $font-family-source, 0),
  $subtitle-2: mat.define-typography-level(16px, 20px, 400, $font-family-source),
  $button: mat.define-typography-level(14px, 16px, 600, $font-family, normal),
  $caption:
    mat.define-typography-level(12px, 18px, 400, $font-family-source, 0.2px)
);

@mixin aid-typography {
  .mat-headline-6 {
    margin-bottom: 0px !important;
  }

  .mat-h3 {
    margin-bottom: 4px !important;
  }

  .mat-subtitle-2 {
    margin-bottom: 0 !important;
  }

  .mat-headline-4 {
    margin-bottom: 0 !important;
  }

  .mat-headline-3 {
    margin-bottom: 0 !important;
  }

  .mat-subheading-1-light {
    $font-size: 16px;
    $font-weight: 200;
    $line-height: 20px;
    $font-family: $font-family-source;
    @include mat-typography-font-shorthand(
      $font-size,
      $font-weight,
      $line-height,
      $font-family
    );
  }
}
