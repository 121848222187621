@import '../themes/_theme';
@import '../base/_typography';

@mixin aid-mat-select-styles() {
  $table-font-size: 14px;
  $table_color: $secondary-text;
  $font-family-source: $font-family-source;
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  mat-select {
    font-size: $table-font-size !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    font-family: $font-family-source !important;
    //color: $secondary-text !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
  mat-option {
    font-size: $table-font-size !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    font-family: $font-family-source !important;
    color: $secondary-text !important;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value:active {
    color: $secondary-text !important;
  }
}
