@use '@angular/material' as mat;
@import 'themes/theme';
@import '../base/_typography';

$font-family-source: $font-family-source;

@mixin aid-form-styles {
  mat-label {
    font-size: 14px;
    font-family: $font-family-source;
  }
  mat-form-field {
    margin-top: 8px;

    .mat-mdc-form-field-subscript-wrapper {
    }

    .mat-mdc-form-field-error {
    }

    .mat-mdc-form-field-infix {
      min-height: 0;
      padding: 3px 3px !important;
      border-top: 0.4375em solid transparent;
      font-family: $font-family-source;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined
      .mat-mdc-form-field-infix {
      padding-top: 0;
      padding-bottom: 0;
    }

    .mdc-notched-outline__trailing {
      border-color: #0000001f !important;
    }

    .mdc-notched-outline__leading {
      border-color: #0000001f !important;
    }

    .mdc-notched-outline__notch {
      display: flex;
      width: fit-content !important;
      border-color: #0000001f !important;
    }

    .mdc-text-field--outlined,
    .mdc-floating-label {
      left: 0 !important;
    }

    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0 !important;
    }

    .mat-mdc-input-element {
      margin-left: 5px;
      margin-bottom: 5px;
    }

    .mat-mdc-text-field-wrapper {
      margin: 0 !important;
      padding: 0;
    }

    .mat-mdc-form-field-flex {
      height: 40px;
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      font-family: $font-family-source;
      color: $secondary-text;
    }

    .mat-mdc-input-element {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      font-family: $font-family-source;
      color: $secondary-text;
    }

    mat-icon {
      color: $primary-light-color;
      margin-right: 8px;
    }

    // Select arrow for dropdown
    .mat-mdc-select-arrow-wrapper {
      margin-right: 10px;
    }

    // Error style
    &.mat-form-field-invalid {
      // red color with prefix icon
      .mat-mdc-form-field-prefix {
        mat-icon {
          color: map_get(mat.$red-palette, 900);
        }
      }
    }
  }
}
