@import 'themes/theme';

@mixin aid-mat-expansion-panel-styles() {
  .mat-expansion-panel {
    box-shadow: 6px 5px 20px 0 rgba(46, 58, 89, 0.1) !important;
    border: 1px solid rgb(197, 206, 224, 0.2);
    border-radius: 8px !important;
  }

  .mat-expansion-panel-header {
    padding: 0 16px !important;
  }
}
