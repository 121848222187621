@import 'base/typography';
@import 'themes/theme';

@mixin aid-mat-tab-styles() {
  ::ng-deep {
    .mat-tab-label {
      height: 56px !important;
      color: map_get($mat-primary, 700);

      $font-size: 16px;
      $font-weight: 400;
      $line-height: 56px;
      $font-family: $font-family-source;
      @include mat-typography-font-shorthand(
        $font-size,
        $font-weight,
        $line-height,
        $font-family
      );
    }
  }
}
