@import 'themes/theme';

@mixin aid-button-styles {
  button {
  }

  .mat-mdc-unelevated-button {
    border-radius: 8px !important;
    box-shadow: 0 5px 5px 0 rgba(25, 32, 56, 0.1) !important;
  }

  .mat-mdc-unelevated-button.mat-primary {
    box-shadow: 0 5px 5px 0 rgba(1, 96, 100, 0.25) !important;
  }

  .mat-mdc-unelevated-button.mat-warn {
    box-shadow: 0 2px 10px 0 rgba(255, 61, 113, 0.5) !important;
  }

  .mat-mdc-unelevated-button.mat-accent {
    box-shadow: 0 2px 6px 0 rgba(38, 134, 150, 0.5) !important;
  }

  .mat-mdc-unelevated-button[disabled][disabled] {
    box-shadow: 0 5px 5px 0 rgba(25, 32, 56, 0.1) !important;
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    color: map_get($basic, 500); // default style when background is white
  }
}
