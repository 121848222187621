@use '@angular/material' as mat;
@import '../../../node_modules/@angular/material/theming';

$dark-primary-text: #000000;
$light-primary-text: #ffffff;

$basic: (
  200: #f7f9fc,
  300: #edf1f7,
  400: #e4e9f2,
  500: #c5cee0,
  600: #8f9bb3,
  700: #2e3a59,
  800: #222b45,
  900: #192038
);

$mat-primary: (
  50: #e0f3f4,
  100: #c4f7e8,
  200: #90efdb,
  300: #54d0c0,
  400: #26a7b4,
  500: #016064,
  600: #014b56,
  700: #003847,
  800: #01273a,
  900: #001d2f,
  A100: #a9efff,
  A200: #76e6ff,
  A400: #43ddff,
  A700: #2ad8ff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text
  )
);
/*
$mat-primary: (
  50: var(--theme-primary-50),
  100: var(--theme-primary-100),
  200: var(--theme-primary-200),
  300: var(--theme-primary-300),
  400: var(--theme-primary-400),
  500: var(--theme-primary-500),
  600: var(--theme-primary-600),
  700: var(--theme-primary-700),
  800: var(--theme-primary-800),
  900: var(--theme-primary-900),
  A100: var(--theme-primary-A100),
  A200: var(--theme-primary-A200),
  A400: var(--theme-primary-A400),
  A700: var(--theme-primary-A700),
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text
  )
);*/

$mat-accent: (
  50: #e0ecec,
  100: #d9faf1,
  200: #b2f6e9,
  300: #86e6dc,
  400: #64ceca,
  500: #37a8ae,
  600: #268696,
  700: #1b677e,
  800: #124b64,
  900: #093754,
  A100: #6ef4ff,
  A200: #3bf0ff,
  A400: #08ecff,
  A700: #00dbed,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text
  )
);

/*$mat-accent: (
  50 : var(--theme-secondary-50),
  100 : var(--theme-secondary-100),
  200 : var(--theme-secondary-200),
  300 : var(--theme-secondary-300),
  400 : var(--theme-secondary-400),
  500 : var(--theme-secondary-500),
  600 : var(--theme-secondary-600),
  700 : var(--theme-secondary-700),
  800 : var(--theme-secondary-800),
  900 : var(--theme-secondary-900),
  A100 : var(--theme-secondary-A100),
  A200 : var(--theme-secondary-A200),
  A400 : var(--theme-secondary-A400),
  A700 : var(--theme-secondary-A700),
  contrast: (
    50: var(--theme-secondary-contrast-50),
    100: var(--theme-secondary-contrast-100),
    200: var(--theme-secondary-contrast-200),
    300: var(--theme-secondary-contrast-300),
    400: var(--theme-secondary-contrast-400),
    500: var(--theme-secondary-contrast-500),
    600: var(--theme-secondary-contrast-600),
    700: var(--theme-secondary-contrast-700),
    800: var(--theme-secondary-contrast-800),
    900: var(--theme-secondary-contrast-900),
    A100: var(--theme-secondary-contrast-A100),
    A200: var(--theme-secondary-contrast-A200),
    A400: var(--theme-secondary-contrast-A400),
    A700: var(--theme-secondary-contrast-A700),
  )
);*/

$mat-warn: (
  50: #fee0e0,
  100: #fcb3b3,
  200: #fa8080,
  300: #f74d4d,
  400: #f62626,
  500: #ff3d71,
  600: #f30000,
  700: #f10000,
  800: #ef0000,
  900: #ec0000,
  A100: #ffffff,
  A200: #ffe0e0,
  A400: #ffadad,
  A700: #ff9393,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text
  )
);

$primary: mat.define-palette($mat-primary);
$accent: mat.define-palette($mat-accent);
$warn: mat.define-palette($mat-warn);

$primary-super-light-color: map_get($mat-primary, 200);
$primary-light-color: map_get($mat-primary, 300);
$primary-color-400: map_get($mat-primary, 400);
$primary-color: map_get($mat-primary, 500);
$primary-color-600: map_get($mat-primary, 600);
$primary-color-700: map_get($mat-primary, 700);
$primary-dark-color: map_get($mat-primary, 900);
$warn-color: map_get($mat-warn, 500);
$accent-color: map_get($mat-accent, 600);
$accent-color-500: map_get($mat-accent, 500);
$accent-color-600: map_get($mat-accent, 600);
$accent-color-100: map_get($mat-accent, 100);
$secondary-text: #1a2138;

$gray: #dadce0;
$primary-light-color-50: map_get($mat-primary, 50);
$black-shadow-08: rgba(0, 0, 0, 0.08);
$black-shadow-2: rgba(0, 0, 0, 0.2);
$black-shadow-4: rgba(0, 0, 0, 0.4);
$black-shadow-5: rgba(0, 0, 0, 0.5);
$black-shadow-6: rgba(0, 0, 0, 0.6);
$black-shadow-8: rgba(0, 0, 0, 0.8);
$black-shadow-033: rgba(0, 0, 0, 0.33);

$color: #36a8ae;

$table-color: rgba(0, 0, 0, 0.6);

$icon-color: map-get($basic, 600);
$basic-super-light-color: map-get($basic, 200);
$basic-light-color: map-get($basic, 300);
$basic-color-200: map-get($basic, 200);
$basic-color-300: map-get($basic, 300);
$basic-color-400: map-get($basic, 400);
$basic-color-500: map_get($basic, 500);
$basic-color-600: map-get($basic, 600);
$basic-color-700: map-get($basic, 700);
$basic-color-800: map-get($basic, 800);
$basic-color-900: map-get($basic, 900);

$basic-light-color-300: map-get($basic, 300);

$damage-color: #ff4f5a;

$divider: $basic-light-color;

.brand-color {
  color: $primary-color;
}

.text-color {
  color: $secondary-text;
}

.basic-color-900 {
  color: $basic-color-900;
}

.basic-color-600 {
  color: $basic-color-600;
}

.basic-color-700 {
  color: $basic-color-700;
}

.accent-color {
  color: $accent-color;
}

.warn-color {
  color: $warn-color;
}
