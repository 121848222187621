@import '../themes/_theme';
@import '../base/_typography';

$table-header-height: 40px;

@mixin aid-table-styles() {
  $table-font-size: 16px;
  $table_color: $basic-color-700;
  $table-font: $font-family-source;

  .table {
    margin: 0;
    scrollbar-width: none;
  }

  .mat-mdc-table {
    td {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  th.mat-cell,
  td.mat-cell {
    border-bottom-color: rgba(map_get($basic, 900), 0.1) !important;
    font-size: $table-font-size;
    line-height: 24px;
    font-weight: 400;
    font-family: $table-font;
    color: $table_color;
    padding: 16px 4px !important;
  }

  th.mat-header-cell {
    font-family: $table-font;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 18px !important;
    color: $basic-color-500 !important;
  }

  tr.mat-header-row {
    height: $table-header-height !important;
  }

  th.mat-header-cell {
    padding: 16px 4px !important;
    border-bottom-color: rgba(151, 151, 151, 0.25) !important;
  }

  td.cdk-column-actions {
    text-align: right;
  }
}
